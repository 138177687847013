import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function AddGoodsModal() {                   //добавление товаров, доступное обычному админу, можно добавлять только мойщиков 1 и 2 ранга
  
const [newGoods, setNewGoods]=useState([]);
const api_url = process.env.REACT_APP_API_URL;
const complex_name = localStorage.getItem('complex_name');
const [saveStatus, setSaveStatus] = useState("");
const [saveButtonDisable, setSaveButtonDisable] = useState(false);                    //по умолчанию кнопка сабмит выключена, проверяем, что номер телефона введен верно, после этого включаем ее 
const [allGoodsCategories, setAllGoodsCategories] = useState([]);


useEffect(() => {

  async function getGoodsCategories() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({})
      };
      const goodsCategories = await (await fetch(api_url+"/api/allGoodsCategories", requestOptions)).json();
      setAllGoodsCategories(goodsCategories.map(({goods_category_id, goods_category_name}) => ({goodsCategoryId: goods_category_id, goodsCategoryName: goods_category_name})));
  }
  getGoodsCategories();
  }, []);

function handleChange(e) {
    setNewGoods({
      ...newGoods,
      [e.target.name]: e.target.value
    });
}

async function createGoods() {
    
    setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({ price_category: newGoods.priceCategory, goods_category: newGoods.goodsCategory, goods_name: newGoods.goodsName, price: newGoods.price, salary: newGoods.salary})
    };

    const saving_goods = await fetch(api_url+"/api/addGoods", requestOptions);

    const body = await saving_goods.json();
    if (body.message === "Goods added successfully!") {
      setSaveStatus("success");
    }
    else {
      setSaveStatus("error");
      setSaveButtonDisable(false);
    }
}

return (
    <div className='edit-worker-modal'>
    <h1 className="modal-header"> Добавить товар </h1>
      <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel id="demo-simple-select-label">Категория:</InputLabel>
        <Select
          value={newGoods.goodsCategory}
          label="Категория:"
          name="goodsCategory"
          fullWidth
          onChange={handleChange}
        >
        {allGoodsCategories.map(({goodsCategoryId, goodsCategoryName}) => (                           //листим каталог работ
        <MenuItem key={goodsCategoryId} value={goodsCategoryName}> {goodsCategoryName} </MenuItem>
        ))}
        </Select>
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Название:</InputLabel>
        <OutlinedInput
          id="goods_name"
          name="goodsName"
          value={newGoods.goodsName}
          onChange={handleChange}
          label="Название"
        />
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
            <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="price-category"
                name="priceCategory"
                value={newGoods.priceCategory}
                label="Филиал"
                onChange={handleChange}
            >
            <MenuItem value={"ohta_only"}>Охта</MenuItem>
            <MenuItem value={"other"}>Кудрово</MenuItem>
            <MenuItem value={"all"}>Все филиалы</MenuItem>
            </Select>
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">Стоимость</InputLabel>
        <OutlinedInput
          id="goods_price"
          name="price"
          value={newGoods.price}
          onChange={handleChange}
          label="Стоимость"
        />
        </FormControl>

        <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
        <InputLabel htmlFor="component-outlined">ЗП</InputLabel>
        <OutlinedInput
          id="goods_salary"
          name="salary"
          value={newGoods.salary}
          onChange={handleChange}
          label="ЗП"
        />
        </FormControl>
        
        <Button variant="contained"
            sx={{mb:1.5, mt:1.5}} fullWidth
            disabled={saveButtonDisable}
            color="success"
            className="mr10"
            onClick={() => createGoods()}>Добавить товар</Button>
        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
    </div>
)}