import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

export default function MotivationInfo() {
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [motivationState, setMotivationState] = useState("admin");
    const api_url = process.env.REACT_APP_API_URL;
    const [adminFinanceInfo, setAdminFinanceInfo] = useState([]);   
    const [cashierFinanceInfo, setCashierFinanceInfo] = useState([]);  
    const [qualityControlFinanceInfo, setQualityControlFinanceInfo] = useState([]);  

    React.useEffect(() => {                                         //функция, которая вызывается при загрузке страницы
        async function getAdminFinanceInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
            body: JSON.stringify({ start_date: startDate, end_date: endDate })
          };
          const admin_finance_info = await fetch(api_url+"/api/adminFinanceInfo", requestOptions);
          const body = await admin_finance_info.json();
          setAdminFinanceInfo(body.map(({ admin_id, admin_name, total_cash, visits_number, shift_number, services_turnover, average_bill, admin_salary_bonus, visits_number_kpi, average_bill_rate, total_salary, average_bill_plan }) => ({ admin_id: admin_id, admin_name: admin_name, total_cash: total_cash, visits_number: visits_number, shift_number: shift_number, services_turnover: services_turnover, average_bill: average_bill, admin_salary_bonus: admin_salary_bonus, visits_number_kpi: visits_number_kpi, average_bill_rate: average_bill_rate, total_salary: total_salary, average_bill_plan: average_bill_plan })));
        }

        async function getCashierFinanceInfo() {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
              body: JSON.stringify({ start_date: startDate, end_date: endDate })
            };
            const cashier_finance_info = await fetch(api_url+"/api/cashierFinanceInfo", requestOptions);
            const body = await cashier_finance_info.json();
            setCashierFinanceInfo(body.map(({ cashier_id, cashier_name, total_cash, visits_number, shift_number, total_goods, total_cert_bonus, kpi, total_goods_salary }) => ({ cashier_id: cashier_id, cashier_name: cashier_name, total_cash: total_cash, visits_number: visits_number, shift_number: shift_number, total_goods: total_goods, total_cert_bonus: total_cert_bonus, kpi: kpi, total_goods_salary: total_goods_salary })));
        }

        async function getQualityControlFinanceInfo() {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')},
              body: JSON.stringify({ start_date: startDate, end_date: endDate })
            };
            const cashier_finance_info = await fetch(api_url+"/api/qualityControlFinanceInfo", requestOptions);
            const body = await cashier_finance_info.json();
            setQualityControlFinanceInfo(body.map(({ quality_control_id, quality_control_name, visits_number, shift_number, kpi, fines, salary, salary_without_kpi, bonuses }) => ({ quality_control_id: quality_control_id, quality_control_name: quality_control_name, visits_number: visits_number, shift_number: shift_number, kpi: kpi, fines: fines, salary: salary, salary_without_kpi: salary_without_kpi, bonuses: bonuses })));
        }

        if (motivationState == "admin"){
            getAdminFinanceInfo();
        }
        else if (motivationState == "cashier"){
            getCashierFinanceInfo();
        }
        else if (motivationState == "quality_control"){
            getQualityControlFinanceInfo();
        }
        }, [startDate, endDate, motivationState]);

    return (
        <>
            <head>
                <title>Мотивация</title>
                <meta property="og:title" content="Dashboard" key="title" />
            </head>

            <Box sx={{pt:5, pb:5}} fullWidth>
            <TextField
                id="start_date"
                label="Start Date"
                type="date"
                defaultValue={startDate}
                sx={{ m: 1.5, width: 220}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setStartDate(e.target.value)}
            />

            <TextField
                id="end_date"
                label="End Date"
                type="date"
                defaultValue={endDate}
                sx={{ m: 1.5, width: 220 }}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setEndDate(e.target.value)}
            />

            <FormControl sx={{mb:1.5, mt:1.5, width: 300}} >
            <InputLabel id="demo-simple-select-label">Выбор категории работников:</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="motivation-state"
                name="motivationState"
                value={motivationState}
                label="Выбор категории работников"
                onChange={e => setMotivationState(e.target.value)}
            >
            <MenuItem value={"admin"}>Администраторы</MenuItem>
            <MenuItem value={"cashier"}>Кассир</MenuItem>
            <MenuItem value={"quality_control"}>Контроль Качества</MenuItem>
            </Select>
            </FormControl>

            </Box>

            {/* тут таблица по мотивации админов */}
            {motivationState == "admin" && <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Поступления в кассу</TableCell>
                <TableCell>Количество визитов</TableCell>
                <TableCell>Количество смен</TableCell>
                <TableCell>Оборот по услугам</TableCell>
                <TableCell>Средний чек</TableCell>
                <TableCell>Средний чек (План)</TableCell>
                <TableCell>Новый KPI</TableCell>
                <TableCell>Коэффициент за количество машин</TableCell>
                <TableCell>Коэффициент за средний чек</TableCell>
                <TableCell>ЗП с KPI</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {adminFinanceInfo.map(({ admin_id, admin_name, total_cash, visits_number, shift_number, services_turnover, average_bill, average_bill_plan, admin_salary_bonus, visits_number_kpi, average_bill_rate, total_salary }) => (
            <TableRow>
                <TableCell>{admin_name}</TableCell>
                <TableCell>{total_cash}</TableCell>
                <TableCell>{visits_number}</TableCell>
                <TableCell>{shift_number}</TableCell>
                <TableCell>{services_turnover}</TableCell>
                <TableCell>{average_bill}</TableCell>
                <TableCell>{average_bill_plan}</TableCell>
                <TableCell>{admin_salary_bonus}</TableCell>
                <TableCell>{visits_number_kpi}</TableCell>
                <TableCell>{average_bill_rate}</TableCell>
                <TableCell>{total_salary}</TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer>}

            {/* тут таблица по мотивации кассиров */}
            {motivationState == "cashier" && <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Поступления в кассу</TableCell>
                <TableCell>Количество визитов</TableCell>
                <TableCell>Количество смен</TableCell>
                <TableCell>ЗП за товары</TableCell>
                <TableCell>KPI</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {cashierFinanceInfo.map(({ cashier_id, cashier_name, total_cash, visits_number, shift_number, kpi, total_goods_salary }) => (
            <TableRow>
                <TableCell>{cashier_name}</TableCell>
                <TableCell>{total_cash}</TableCell>
                <TableCell>{visits_number}</TableCell>
                <TableCell>{shift_number}</TableCell>
                <TableCell>{total_goods_salary}</TableCell>
                <TableCell>{kpi}</TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer> }

            {/* тут таблица по мотивации контроля качества */}
            {motivationState == "quality_control" && <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>Сотрудник</TableCell>
                <TableCell>Количество визитов</TableCell>
                <TableCell>Количество смен</TableCell>
                <TableCell>ЗП</TableCell>
                <TableCell>KPI</TableCell>
                <TableCell>Штрафы</TableCell>
                <TableCell>Премии</TableCell>
                <TableCell>ЗП c KPI</TableCell>
                <TableCell></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {qualityControlFinanceInfo.map(({ quality_control_id, quality_control_name, visits_number, shift_number, kpi, fines, bonuses, salary_without_kpi, salary }) => (
            <TableRow>
                <TableCell>{quality_control_name}</TableCell>
                <TableCell>{visits_number}</TableCell>
                <TableCell>{shift_number}</TableCell>
                <TableCell>{salary_without_kpi}</TableCell>
                <TableCell>{kpi}</TableCell>
                <TableCell>{fines}</TableCell>
                <TableCell>{bonuses}</TableCell>
                <TableCell>{salary}</TableCell>
            </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer> }
        </>
    )
}
