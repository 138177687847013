import React, { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function EditGoodsModal({editedGoodsId}) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false); 
    const [saveStatus, setSaveStatus] = useState(""); 
    const api_url = process.env.REACT_APP_API_URL;
    const [goodsId, setGoodsId] = useState("");
    const [goodsCategory, setGoodsCategory] = useState("");
    const [allGoodsCategories, setAllGoodsCategories] = useState([]);
    const [goodsName, setGoodsName] = useState("");
    const [price, setPrice] = useState("");
    const [priceCategory, setPriceCategory] = useState("");
    const [salary, setSalary] = useState("");


        useEffect(() => {
        async function getGoodsInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ goods_id: editedGoodsId })
          };
          const goods = await (await fetch(api_url+"/api/selectedGoodsInfo", requestOptions)).json();

          setGoodsId(goods.goods_id);
          setGoodsCategory(goods.goods_category);
          setGoodsName(goods.goods_name);
          setPrice(goods.price);
          setSalary(goods.salary);
          setPriceCategory(goods.price_category);
        }

        async function getGoodsCategories() {
            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
              body: JSON.stringify({})
            };
            const goodsCategories = await (await fetch(api_url+"/api/allGoodsCategories", requestOptions)).json();
            setAllGoodsCategories(goodsCategories.map(({goods_category_id, goods_category_name}) => ({goodsCategoryId: goods_category_id, goodsCategoryName: goods_category_name})));
        }

        getGoodsInfo();
        getGoodsCategories();
        }, []);

        async function updateGoodsInfo() {
    
            setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ price_category: priceCategory, goods_id: goodsId, goods_category: goodsCategory, goods_name: goodsName, price: price, salary: salary })
            };

            const saving_goods = await fetch(api_url+"/api/updateGoodsInfo", requestOptions);

            const body = await saving_goods.json();
            if (body.message === "Goods info updated successfully!") {
              setSaveStatus("success");
            }
            else {
              setSaveStatus("error");
              setSaveButtonDisable(false);
            }
        }

        return (
            <div className='edit-worker-modal'>
                <h1 className="modal-header"> Изменить информацию о товаре </h1>
                <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Категория:</InputLabel>
                <Select
                    value={goodsCategory}
                    label="Категория:"
                    name="goodsCategory"
                    fullWidth
                    onChange={e => setGoodsCategory(e.target.value)}
                >
                    {allGoodsCategories.map(({goodsCategoryId, goodsCategoryName}) => (                           //листим каталог работ
                    <MenuItem key={goodsCategoryId} value={goodsCategoryName}> {goodsCategoryName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="goods_name"
                    label="Название"
                    name="goodsName"
                    value={goodsName}
                    onChange={e => setGoodsName(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
                <Select
                    key = {priceCategory}
                    id="price-category"
                    label="Филиал"
                    name="priceCategory"
                    value={priceCategory}
                    onChange={e => setPriceCategory(e.target.value)}
                >
                <MenuItem value={"all"}>Все филиалы</MenuItem>
                <MenuItem value={"other"}>Кудрово</MenuItem>
                <MenuItem value={"ohta_only"}>Охта</MenuItem>
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="goods_price"
                    label="Стоимость"
                    name="price"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <TextField
                    id="goods_salary"
                    label="ЗП"
                    name="salary"
                    value={salary}
                    onChange={e => setSalary(e.target.value)}
                    />
                </FormControl>
        
                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    disabled={saveButtonDisable}
                    color="success"
                    className="mr10"
                    onClick={() => updateGoodsInfo()}>Сохранить изменения</Button>

        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
            </div>
        );
}