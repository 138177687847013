import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function EditBonus({editedBonusId, availableWorkers}) {
    const [saveButtonDisable, setSaveButtonDisable] = useState(false); 
    const [saveStatus, setSaveStatus] = useState(""); 
    const api_url = process.env.REACT_APP_API_URL;
    const [bonusId, setBonusId] = useState("");
    const [bonusAmount, setBonusAmount] = useState("");
    const [complexName, setComplexName] = useState(""); 
    const [workerName, setWorkerName] = useState("");
    const [workerId, setWorkerId] = useState("");
    const [comment, setComment] = useState("");

        useEffect(() => {
        async function getBonusInfo() {
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
            body: JSON.stringify({ bonus_id: editedBonusId })
          };
          const bonus = await (await fetch(api_url+"/api/BonusInfo", requestOptions)).json();

          setBonusId(bonus.bonus_id);
          setBonusAmount(bonus.bonus_amount);
          setComplexName(bonus.complex_name);
          setWorkerName(bonus.worker_name);
          setWorkerId(bonus.worker_id);
          setComment(bonus.comment);
        }

        getBonusInfo();
        }, []);

        async function updateBonusInfo() {
    
            setSaveButtonDisable(true);                               //сразу выключаю кнопку сохранения, чтобы не нажимали дважды

            let worker_info = availableWorkers.find((s) => s.workerId === workerId)
            let worker_name = worker_info.fullName;
    
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
                body: JSON.stringify({ bonus_id: bonusId, bonus_amount: bonusAmount, complex_name: complexName, worker_id: workerId, worker_name: worker_name, comment: comment })
            };

            const saving_bonus = await fetch(api_url+"/api/updateBonusInfo", requestOptions);

            const body = await saving_bonus.json();
            if (body.message === "Bonus info updated successfully!") {
              setSaveStatus("success");
            }
            else {
              setSaveStatus("error");
              setSaveButtonDisable(false);
            }
        }

        return (
            <div className='edit-worker-modal'>
                <h1 className="modal-header"> Изменить информацию о премии </h1>
                <Box sx={{ maxWidth: 550, margin: `auto`, pt: 5, pb: 5 }}>
                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel id="demo-simple-select-label">Сотрудник:</InputLabel>
                <Select
                    value={workerId}
                    label="Сотрудник:"
                    name="workerId"
                    fullWidth
                    onChange={e => setWorkerId(e.target.value)}
                >
                    {availableWorkers.map(({workerId, fullName}) => (                           //листим каталог сотрудников
                    <MenuItem key={workerId} value={workerId}> {fullName} </MenuItem>
                    ))}
                </Select>
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel htmlFor="component-outlined">Размер премии</InputLabel>
                <OutlinedInput
                    label="Размер премии"
                    name="bonusAmount"
                    value={bonusAmount}
                    onChange={e => setBonusAmount(e.target.value)}
                />
                </FormControl>

                <FormControl sx={{mb:1.5, mt:1.5}} fullWidth >
                <InputLabel htmlFor="component-outlined">Комментарий</InputLabel>
                <OutlinedInput
                    label="Комментарий"
                    name="comment"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                />
                </FormControl>
        
                <Button variant="contained"
                    sx={{mb:1.5, mt:1.5}} fullWidth
                    disabled={saveButtonDisable}
                    color="success"
                    className="mr10"
                    onClick={() => updateBonusInfo()}>Сохранить изменения</Button>

        {saveStatus === "error" && <Alert severity="error">Ошибка, проверьте правильность введенных данных!</Alert>}
        {saveStatus === "success" && <Alert severity="success">Изменения успешно сохранены!</Alert>}
        </Box>
            </div>
        );
}